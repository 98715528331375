import zones from './zipcodeData';

export const validZipcodes = zones
  .reduce((currentZipcodes, zone) => currentZipcodes.concat(Object.keys(zone.zipcodes)), []);

export const getZone = zipcode => zones.find(zone => Object.keys(zone.zipcodes).includes(zipcode));

export const getDeliveryFees = (zipcode) => {
  const zone = getZone(zipcode);
  if (!zone) return undefined;
  const zipcodeDeliveryFee = zone.zipcodes[zipcode].deliveryFee;
  if (typeof zipcodeDeliveryFee === 'number') {
    return zipcodeDeliveryFee;
  }
  return getZone(zipcode).deliveryFee;
};

export function isInZone({ latitude, longitude }, zone) {
  const { cornerNW, cornerSE } = zone;
  return cornerNW.latitude >= latitude && latitude >= cornerSE.latitude
    && cornerNW.longitude <= longitude && longitude <= cornerSE.longitude;
}

export function checkPosition({ latitude, longitude }, zonesToCheck = zones) {
  return zonesToCheck
    .reduce((response, zone) => response || isInZone({ latitude, longitude }, zone), false);
}

export default function checkError(zipcode) {
  if (typeof zipcode !== 'string' || zipcode.length !== 5) {
    return {
      message: 'Merci d\'entrer un code postal à 5 chiffres',
      code: 1,
    };
  }
  if (!validZipcodes.includes(zipcode)) {
    return {
      message: 'Votre ville n\'est pas encore couverte par notre service. Voici la liste des villes couvertes pour le moment : Paris et une partie de l‘Ile de France, Marseille, Lyon, Bordeaux et Aix-en-Provence.', // eslint-disable-line max-len
      code: 2,
    };
  }
  return {
    message: '',
    code: 0,
  };
}
