import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import styled from 'styled-components';

import withAppContext from '../../withAppContext';
import withAuthContext from '../../context/withAuthContext';
import routesMap from '../../Routes';
import { trackEvent } from '../../services/analytics';
import featureSwitch from '../../services/featureSwitch';
import DropdownList from './v2/DropdownList';
import PopupDeliveryFees from './PopupDeliveryFees';
import OutInSideClick from './v2/OutInSideClick';
import {
  contentWidth, margins, colors, fontSizes, mobileThresholdPixels,
  menuBarHeightPixels, mobileMenuBarHeightPixels, Row,
} from '../../components/Home/v2/StyledComponents';

import dropdownArrow from '../../assets/arrow-dropdown-list.svg';
import logoTilli from '../../assets/logos/tilli.navy.svg';
import logoTheKooples from '../../assets/brand/TheKooplesLogo.svg';
import logo85Degres from '../../assets/brand/85degresLogo.png';
import logoCasablanca from '../../assets/brand/CasablancaLogo.png';
import logoGaleriesLafayette from '../../assets/brand/GaleriesLafayetteLogo.svg';
import logoSezane from '../../assets/brand/SezaneLogo.jpg';
import logoSessun from '../../assets/brand/SessunLogo.png';
import logoCaroll from '../../assets/brand/CarollLogo.png';

const servicesList = [
  { index: 0, text: 'Réserver', url: routesMap.Step1.url, trackingId: 'reserver' },
  {
    index: 1,
    text: 'Recherche code postal',
    onClickAction: 'displayFeesPopup',
    trackingId: 'recherche-code-postal',
  },
  featureSwitch('home_showTutorial')
    ? { index: 2, text: 'Tutorial', onClickAction: 'displayTutorial' }
    : undefined,
  { index: 4, text: 'Notre histoire', url: routesMap.AboutPage.url, trackingId: 'notre-histoire' },
  { index: 5, text: 'Ils parlent de nous', url: routesMap.PressPage.url, trackingId: 'ils-parlent-de-nous' },
  { index: 6, text: 'Nos artisans', url: routesMap.OurArtisans.url, trackingId: 'nos-couturiers' },
  { index: 7, text: 'Nos tarifs', url: routesMap.PricingsPage.url, trackingId: 'nos-tarifs' },
  { index: 8, text: 'FAQ', url: routesMap.FAQPage.url, trackingId: 'faq' },
  featureSwitch('auth_shops')
    ? { index: 9, text: 'se déconnecter', onClickAction: 'logout' }
    : undefined,
].filter(item => !!item);

const mobileMenu = [
  { index: 0, text: 'Réserver', url: routesMap.Step1.url, trackingId: 'Reserver' },
  {
    index: 1,
    text: 'Recherche code postal',
    onClickAction: 'displayFeesPopup',
    trackingId: 'recherche-code-postal',
  },
  featureSwitch('home_showTutorial')
    ? { index: 2, text: 'Tutorial', onClickAction: 'displayTutorial' }
    : undefined,
  {
    index: 3,
    text: 'FAQ',
    url: routesMap.FAQPage.url,
    trackingId: 'FAQ',
  },
  { index: 4, text: 'Notre histoire', url: routesMap.AboutPage.url, trackingId: 'Notre-histoire' },
  {
    index: 5,
    text: 'Ils parlent de nous / Presse',
    url: routesMap.PressPage.url,
    trackingId: 'Ils-parlent-de-nous-Presse',
  },
  { index: 6, text: 'Nos tarifs', url: routesMap.PricingsPage.url, trackingId: 'nos-tarifs' },
  { index: 7, text: 'Nos artisans', url: routesMap.OurArtisans.url, trackingId: 'Nos-artisans' },
  { index: 8, text: 'La rubrique / Blog', url: routesMap.LaRubrique.url, trackingId: 'La-rubrique-Blog' },
  featureSwitch('auth_shops')
    ? { index: 9, text: 'se déconnecter', onClickAction: 'logout' }
    : undefined,
].filter(item => !!item);

const BarContainer = styled.div`
  position: fixed;
  top: 0px;
  width: 100vw;
  height: ${menuBarHeightPixels};
  z-index: 3;
  background-color: ${colors.white};
  display: flex;
  align-items: center;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, .3);

  @media (max-width: ${mobileThresholdPixels}) {
    background-color: ${colors.navy};
    height: ${mobileMenuBarHeightPixels};
    border-bottom-width: 0px;
  }
`;

const Content = styled.div`
  position: relative;
  width: ${contentWidth}px;
  margin: auto;
  display: flex;
  align-items: center;
`;

const StyledRow = styled(Row)`
  width: 100%;
`;

const TextLink = styled.div`
  width: max-content;
  color: ${colors.navy};
  font-family: Gotham;
  font-size: ${fontSizes.s};
  font-weight: bold;
  text-transform: uppercase;
  text-decoration: none;
  padding: 0px ${margins.m} 0px 0px;
  ${props => props.right && `padding: 0px 0px 0px ${margins.m};`};
  ${props => props.middle && `
    padding: 0px;
    display: flex;
    flex-direction: column;
    align-items: center;`};
  flex: 1;
  cursor: pointer;

  &:hover {
    opacity: 0.7;
  }

  @media (max-width: ${mobileThresholdPixels}) {
    padding: ${props => props.right ? `0px ${margins.s} 0px 0px ` : `0px 0px 0px ${margins.s}`};
    color: ${colors.white};
    font-size: ${fontSizes.s};
    text-align: center;
  }
`;

const LinkContainer = styled.div`
  text-align: center;
  ${props => props.logo && 'margin: auto'};

  @media (max-width: ${mobileThresholdPixels}) {
    margin-top: 0px;
  }
`;

const LeftPart = styled.div`
  position: absolute;
  left: 0px;
  display: flex;
  flex-direction: row;
`;

const RightPart = styled.div`
  position: absolute;
  right: 15px;
  display: flex;
  flex-direction: row;
`;


const LogoContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: ${colors.navy};
  font-size: 14px;

  @media (max-width: ${mobileThresholdPixels}) {
    height: 100px;
    border-bottom: solid 2px ${colors.lightGrey};
  }
`;

const LogoImg = styled.img`
  width: 200px;
  max-height: 50px;
  object-fit: contain;
`;

const Img = styled.img`
  padding-bottom: 1px;
  margin-left: 4px;
  ${props => props.rotate && 'transform: rotate(180deg)'};
`;

const StyledLink = styled(Link)`
  flex: ${({ noflex }) => noflex ? 0 : 1};
  text-align: center;
  text-decoration: none;
`;

const ModalBackground = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 6;
  background-color: ${colors.navyTransparent};
`;

const IframeContainer = styled.div`
  position: absolute;
  top: ${({ top }) => top}px;
  left: ${({ left }) => left}px;
`;

function getLogoSource() {
  switch (process.env.GATSBY_BRAND) {
    case 'THE_KOOPLES':
      return logoTheKooples;
    case 'CASABLANCA':
      return logoCasablanca;
    case '85_DEGRES':
      return logo85Degres;
    case 'GALERIES_LAFAYETTE':
      return logoGaleriesLafayette;
    case 'SEZANE':
      return logoSezane;
    case 'SESSUN':
      return logoSessun;
    case 'CAROLL':
      return logoCaroll;
    default:
      return logoTilli;
  }
}

function getCoordsForIframe() {
  if (typeof screen === 'undefined') return { width: 1280, height: 720, top: 0, left: 0 };
  const { width, height } = screen;
  const isDesktop = width > 1200;
  const iframeHeight = isDesktop ? 720 : 280;
  const iframeTop = (height - iframeHeight) / 2;
  const iframeWidth = Math.min(width, 1280);
  const iframeLeft = (width - iframeWidth) / 2;
  return { width: iframeWidth, height: iframeHeight, top: iframeTop, left: iframeLeft };
}

class MenuBar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      displayServicesList: false,
      displayPartnersList: false,
      displayMobileMenuList: false,
      displayTutorial: false,
      displayFeesPopup: false,
    };
    this.ref = React.createRef();
  }

  onMenuItemClick(action) {
    const { authContext: { setAuth } } = this.props;
    switch (action) {
      case 'displayTutorial':
        this.setState({ displayTutorial: true });
        break;
      case 'logout':
        setAuth(false);
        break;
      case 'displayFeesPopup':
        this.setState({ displayFeesPopup: true });
        break;
      default:
        // case not supported
    }
  }

  renderDesktop() {
    const { authContext: { shopName } } = this.props;
    return (
      <Content>
        <LeftPart>
          <LinkContainer>
            <OutInSideClick handleClickOutside={() => this.setState({ displayServicesList: false })} >
              <div>
                <DropdownList
                  display={!!this.state.displayServicesList}
                  dropdownlist={servicesList}
                  onClick={action => this.onMenuItemClick(action)}
                />
                <TextLink
                  onMouseDown={() => this.setState({ displayServicesList: !this.state.displayServicesList })}
                >
                  {'Le service '}
                  <Img
                    src={dropdownArrow}
                    rotate={this.state.displayServicesList ? 'true' : undefined}
                    alt="Menu déroulant"
                  />
                </TextLink>
              </div>
            </OutInSideClick>
          </LinkContainer>
        </LeftPart>
        <LinkContainer logo>
          <StyledLink
            to={routesMap.Step1.url}
            onClick={() => trackEvent('click', 'home_menu')}
          >
            <TextLink middle>
              <LogoImg src={getLogoSource()} alt={'Logo du service de couturière Tilli '} />
              {featureSwitch('auth_shops')
                ? <span>{shopName}</span>
                : null
              }
            </TextLink>
          </StyledLink>
        </LinkContainer>

        <RightPart>
          {!process.env.GATSBY_BRAND &&
            <LinkContainer>
              <StyledLink
                to={routesMap.LaRubrique.url}
                onClick={() => trackEvent('click', 'home_menu-mon_compte')}
              >
                <TextLink>{'Inspirations'}</TextLink>
              </StyledLink>
            </LinkContainer>
          }
          <LinkContainer>
            <StyledLink
              to={routesMap.PricingsPage.url}
              onClick={() => trackEvent('click', 'home_menu-nos-tarifs')}
            >
              <TextLink right>{'Nos tarifs'}</TextLink>
            </StyledLink>
          </LinkContainer>
        </RightPart>
      </Content>
    );
  }

  renderMobile() {
    return (
      <StyledRow justifyContent={'space-between'}>
        <LinkContainer>
          <OutInSideClick handleClickOutside={() => this.setState({ displayMobileMenuList: false })} >
            <DropdownList
              display={!!this.state.displayMobileMenuList}
              dropdownlist={mobileMenu}
              onClick={action => this.onMenuItemClick(action)}
            />
            <TextLink
              onMouseDown={() => this.setState({ displayMobileMenuList: !this.state.displayMobileMenuList })}
            >
              {'Menu'}
            </TextLink>
          </OutInSideClick>
        </LinkContainer>
        <LinkContainer>
          <StyledLink
            to={routesMap.LaRubrique.url}
            onClick={() => trackEvent('click', 'home_menu-mon_compte')}
          >
            <TextLink right>{'Inspirations'}</TextLink>
          </StyledLink>
        </LinkContainer>
      </StyledRow>
    );
  }

  render() {
    const { displayTutorial, displayFeesPopup } = this.state;
    const { context: { isMobile }, authContext: { shopName } } = this.props;
    const { width, height, top, left } = getCoordsForIframe();
    return (
      <div>
        <BarContainer>
          {!isMobile ? this.renderDesktop() : this.renderMobile()}
        </BarContainer>
        {isMobile &&
          <LogoContainer justifyContent={'center'} alignItems={'center'}>
            <StyledLink
              noflex="true"
              to={routesMap.Step1.url}
              onClick={() => trackEvent('click', 'home_menu-logoTilli')}
            >
              <LogoImg
                src={getLogoSource()}
                alt={'logo Tilli atelier de couturières'}
              />
            </StyledLink>
            {featureSwitch('auth_shops')
              ? <span>{shopName}</span>
              : null
            }
          </LogoContainer>
        }
        {displayTutorial &&
          <ModalBackground>
            <OutInSideClick handleClickOutside={() => this.setState({ displayTutorial: false })} >
              <IframeContainer top={top} left={left}>
                <iframe
                  width={width}
                  height={height}
                  src={featureSwitch('home_showTutorial')}
                  frameBorder="0"
                  allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                />
              </IframeContainer>
            </OutInSideClick>
          </ModalBackground>
        }
        {displayFeesPopup &&
          <PopupDeliveryFees closePopup={() => this.setState({ displayFeesPopup: false })} />
        }
      </div>
    );
  }
}

MenuBar.propTypes = {
  context: PropTypes.shape({
    isMobile: PropTypes.bool,
  }).isRequired,
  authContext: PropTypes.shape({
    shopName: PropTypes.string,
    setAuth: PropTypes.func.isRequired,
  }).isRequired,
};

export default withAuthContext(withAppContext(MenuBar));
